import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./components/App";
import {isProduction} from "./components/config";
import {registerHandlers} from "./utils/errors";
import { loadStripe } from "@stripe/stripe-js";
import {hotjar} from "react-hotjar";

/*
  <React.StrictMode>
  </React.StrictMode>,
*/
//const apiPort = process.env.REACT_APP_API_PORT || 3001;

// const stripePromise = loadStripe(process.env.FEATRIX_STRIPE_API_KEY);

// if (isProduction()) {
//     Sentry.init({
//     dsn: "https://8614480d70b4564222823db903b93a07@o4506927948431360.ingest.us.sentry.io/4506927950725120",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire fs, change the sample rate to 100% when sampling sessions where errors occur.
//     });
// } else {
//     console.log("***** NOT production, no Sentry");
// }

if (isProduction()) {
    console.log("Welcome to Featrix!");
    hotjar.initialize({id:"5033089", sv: "6"});
} else {
    console.log("***** NOT production, no hotjar")
}
registerHandlers()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
