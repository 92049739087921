import SyntaxHighlighter from "react-syntax-highlighter";
import anOldHope from "react-syntax-highlighter/dist/esm/styles/hljs/an-old-hope";
import createLogger from "../utils/createLogger";
import { ErrorBoundary } from "@sentry/react";

const DEBUG = true;
const { dbg, enter, leave, logError } = createLogger(DEBUG, "CodeHighlighter.js");


export const DefaultThemedCodeHighlighter = ({ code, language, children, ...rest }) => (
    <ErrorBoundary>
        <SyntaxHighlighter
            language={language ?? "python"}
            style={anOldHope}
            customStyle={{
                background: "var(--featrix-syntax-highlight-bg-color)",
            }}
            {...rest}
        >
            {code ?? children}
        </SyntaxHighlighter>
    </ErrorBoundary>
);
